
import { Vue, Component, Mixins, Emit, Watch } from 'vue-property-decorator';
import store from '@/store';
import $ from 'jquery';
import magnificPopup from 'magnific-popup';
import axios, {AxiosResponse, AxiosError} from 'axios';
import DefaultLayout from './DefaultLayout';
import CommonHeader from '@/components/common/CommonHeaderJa.vue';
import CommonFooter from '@/components/common/CommonFooter.vue';
import ModalFavoritesAlert from '@/components/modal/ModalFavoritesAlert/ModalFavoritesAlert.vue';
import NaviFollowSearchnavi from '@/components/navi/NaviFollowSearchnavi/NaviFollowSearchnavi.vue';
import ModalTodayLowestPrice from '@/components/modal/ModalTodayLowestPrice/ModalTodayLowestPrice.vue';
import ModalMovie from '@/components/modal/ModalMovie/ModalMovie.vue';
import ModalSearchnavi from '@/components/modal/ModalSearchnavi/ModalSearchnavi.vue';
import SiteLogo from '@/components/SiteLogo.vue';
import GlobalMenu from './GlobalMenu/GlobalMenu.vue';
import FloatBottom from './FloatBottom/FloatBottom.vue';

@Component({
  components: {
    CommonHeader,
    CommonFooter,
    ModalFavoritesAlert,
    NaviFollowSearchnavi,
    ModalTodayLowestPrice,
    ModalMovie,
    ModalSearchnavi,
    SiteLogo,
    GlobalMenu,
    FloatBottom,
  },
})
export default class DefaultLayoutLoginJa extends Vue {
}
